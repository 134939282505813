import React from 'react'
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

const Header = (props) => {

    const logo = require("../assets/arango_logo.png");

    return (
        <AppBar color={"secondary"} style={{ position : 'static'}}>
            <Toolbar variant="regular">
                <img src={logo} style={{ height : '81px', width : '174px'}} alt="Arango logo" />
            </Toolbar>
        </AppBar>
    );

}

export default Header;