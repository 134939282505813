import ThemeProvider from '@mui/material/styles/ThemeProvider';
import Toolbar from '@mui/material/Toolbar/Toolbar';
import { Container } from '@mui/system';
import React from 'react'

const Footer = (props) => {

  return (
    <ThemeProvider theme={props.theme}>
        <Toolbar style={{ position : 'static', textAlign : 'center', margin : 0, padding : 0}}>
            <Container maxWidth="sm" style={{ textAlign : 'center', width : '100%'}}>
                <p className='footer-text'>Arango | Hlíðasmári 14 | 201 Kópavogur | s. 534 6800</p>
            </Container>
        </Toolbar>
    </ThemeProvider>
  )

}

export default Footer