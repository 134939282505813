import React from 'react';
import './App.css';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import createTheme from '@mui/material/styles/createTheme';
import responsiveFontSizes from '@mui/material/styles/responsiveFontSizes';
import Header from './components/Header';
import { Container } from '@mui/system';
import Footer from './components/Footer';
import Grid from '@mui/material/Grid/Grid';
import Item from '@mui/material/Grid/Grid';
import Link from '@mui/material/Link/Link';
import { Typography } from '@mui/material';

function App() {

  const importTheme = require('./theme/theme.json');
  let theme = createTheme(importTheme);
  theme = responsiveFontSizes(theme);

  return (
    <ThemeProvider theme={theme}>      
      <Header theme={theme}/>
      <Container className='container' maxWidth="sm" style={{ height : '80vh', marginTop : '20px'}}>
        <Grid container spacing={2} columns={2}>
          <Grid item xs={8}>
            <Item>
              <Typography variant='h2' mt={2}>Áreiðanleikakönnun</Typography>
              <Typography variant='body1' mt={2}>AML Arango er lausn fyrir áreiðanleikakannanir og er þróuð og hýst hjá <Link href='https://arango.is/arango-aml'>Arango</Link></Typography>
            </Item>
          </Grid>
          <Grid item xs={8}>
            <Item>
              <Typography variant='h2' mt={2}>Spurningar</Typography>
              <Typography variant='body1' mt={2}>Ef þú hefur spurningar eða athugasemdir tengdar áreiðanleikakönnun, vinsamlegast hafðu samband við bókhaldsþjónustu- eða endurskoðendafyrirtæki sem sendi þér könnunina</Typography>
            </Item>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </ThemeProvider>
  );
}

export default App;
